import Swal from "sweetalert2"
import { get_date_diff } from "./Helpers";
import { slide_pop_successs } from "./Msgs";
import { update_shift_in_db } from "./Shift";
import { get_worker_from_shift } from "./Workers"

const clock_in_service = async (worker) => {
    Swal.fire({
        background: '#283046',
        html: 
        `<p style="font-size:24px; color:var(--yellow);">${worker.name}</p>`+
        `<i class="material-icons" style="font-size:80px; color:var(--success); margin-top: 10px;">timer</i>`+
        `<p style="margin:10px 0 10px 0; color:var(--yellow); font-size: 18px;">האם להתחיל את שעות השירות?</p>`+
        `<p style="background:var(--success); color:white; text-shadow: 0 0 5px black; border-radius: 3px; padding: 5px;">שעת התחלה: ${new Date().toLocaleString('he')}</p>`+
        ``,
        confirmButtonColor: '#28c76f',
        confirmButtonText: 'אישור',
        cancelButtonText: 'לא כרגע',
        showCancelButton: true,
        focusCancel: true
    }).then((res) => {
        if(res.isConfirmed){
            get_worker_from_shift(worker).service.active = true;
            get_worker_from_shift(worker).service.start_time = new Date();
            update_shift_in_db();
            slide_pop_successs(`${worker.name} התחיל/ה שירות`)
        }
    })
}

const clock_out_service = async (worker) => {
    Swal.fire({
        background: '#283046',
        html: 
        `<p style="font-size:24px; color:var(--yellow);">${worker.name}</p>`+
        `<i class="material-icons" style="font-size:80px; color:var(--success); margin-top: 10px;">timer</i>`+
        `<p style="margin:10px 0 10px 0; color:var(--yellow); font-size: 18px;">האם לסיים את שעות השירות?</p>`+
        `<p style="background:var(--success); color:white; text-shadow: 0 0 5px black; border-radius: 3px; padding: 5px;">שעת התחלה: ${worker.service.start_time.toLocaleString('he')}</p>`+
        `<p style="width:100%; text-align:center; color:var(--yellow); background:var(--main);">שעות שירות</p>`+
        `<div style="width:100%; background: var(--success); display:flex; flex-direction:row; justify-content: space-around; color:white; 
            text-shadow: 0 0 5px black; padding:5px 0 5px 0;">`+
            `<p>שעות: ${get_date_diff(worker.service.start_time).hours}</p>` +
            `<p>דקות: ${get_date_diff(worker.service.start_time).minutes}</p>` +
            `<p>שניות: ${get_date_diff(worker.service.start_time).seconds}</p>`+
        `</div>`,
        confirmButtonColor: '#28c76f',
        confirmButtonText: 'אישור',
        cancelButtonText: 'לא כרגע',
        showCancelButton: true,
        focusCancel: true
    }).then((res) => {
        if(res.isConfirmed){
            get_worker_from_shift(worker).service.active = false
            get_worker_from_shift(worker).service.total_hours = get_date_diff(worker.service.start_time).hours
            get_worker_from_shift(worker).service.total_minutes = get_date_diff(worker.service.start_time).minutes
            get_worker_from_shift(worker).service.end_time = new Date();
            update_shift_in_db();
            slide_pop_successs(`${worker.name} סיימ/ה שירות`)
        }
    })
}

export{clock_in_service, clock_out_service}