import Swal from "sweetalert2"
import store from "../store"
import { slide_pop_successs } from "./Msgs"
import { get_date_diff, get_salary_rate } from "./Helpers"
import { update_shift_in_db } from "./Shift"
import { clock_in_service } from "./Service"

const clock_in_shift = async (worker) => {
    Swal.fire({
        background: '#283046',
        html:
        `<p style="width:100%; tet-align:center; margin-bottom: 10px; font-size:20px; color:var(--yellow);">${worker.name}</p>`+
        // `<img style="height:150px; border-radius:10px; width:auto;" src="${worker.image}"/>`
        `<div class='wo-image' style="width:100%; height:200px; overflow:hidden;">`+
            `<img style="border-radius:10px; max-width:100%; display:block; margin:auto;" src="${worker.image}"/>`+
        `</div>`+
        `<p style="width:100%; background:var(--main); color:whitesmoke;">שעת התחלה: ${new Date().toLocaleString('he')}</p>`,
        confirmButtonText:'התחל משמרת',
        cancelButtonText: 'ביטול',
        showCancelButton: true,
        confirmButtonColor: '#28c76f',
        focusCancel:true,
    }).then(res => {
        if(res.isConfirmed){
            if(!get_worker_from_shift(worker)){
                store.state.shift.workers.push({...worker, shift:{
                    start_time: new Date(),
                    end_time: null,
                    total_hours: 0,
                    total_minutes: 0,
                    salary_income: 0,
                    salary_rate: get_salary_rate(),
                    active: true
                }, service: {
                    active: false,
                    start_time: null,
                    end_time: null,
                    total_hours: 0,
                    total_minutes: 0,
                    total_tip: 0,
                    tip_per_hour: 0
                }})
                slide_pop_successs(`${worker.name} נכנס/ה למשמרת בהצלחה!`)
                console.log(store.state.shift);
                update_shift_in_db();
                if(worker.category == 'מלצרים' || worker.category == 'ברמנים'){
                    clock_in_service(worker);
                }
                return true
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'שגיאה',
                    text: 'העובד כבר במשמרת או היה במשמרת הנוכחית. מנהל יכול לשחזר משמרת באמצעות מסך הניהול.'
                })
                return false
            }
        }
    })
}
const clock_out_shift = async (worker) => {
    Swal.fire({
        background: '#283046',
        html:
        `<p style="width:100%; tet-align:center; margin-bottom: 10px; font-size:20px; color:var(--yellow);">${worker.name}</p>`+
        // `<img style="height:150px; border-radius:10px; width:auto;" src="${worker.image}"/>`
        `<div class='wo-image' style="width:100%; height:200px; overflow:hidden;">`+
            `<img style="border-radius:10px; max-width:100%; display:block; margin:auto;" src="${worker.image}"/>`+
        `</div>`+
        `<p style="width:100%; background:var(--main); color:whitesmoke;">שעת התחלה: <br> ${worker.shift.start_time.toLocaleString('he')}</p>`+
        `<p style="width:100%; background:var(--main); color:whitesmoke;">שעת סיום: <br>${new Date().toLocaleString('he')}</p>`+
        `<div style="width:100%; background: var(--success); display:flex; flex-direction:row; justify-content: space-around; color:white; 
            text-shadow: 0 0 5px black; padding:5px 0 5px 0;">`+
            `<p>שעות: ${get_date_diff(worker.shift.start_time).hours}</p>` +
            `<p>דקות: ${get_date_diff(worker.shift.start_time).minutes}</p>` +
            `<p>שניות: ${get_date_diff(worker.shift.start_time).seconds}</p>`+
        `</div>`+
        `<p style="width:100%; text-align:center; color:var(--yellow); background:var(--main);">שעות שירות</p>`+
        `<div style="width:100%; background: var(--success); display:flex; flex-direction:row; justify-content: space-around; color:white; 
            text-shadow: 0 0 5px black; padding:5px 0 5px 0;">`+
            `<p>שעות: ${get_date_diff(worker.service.start_time, worker.service.end_time).hours}</p>` +
            `<p>דקות: ${get_date_diff(worker.service.start_time, worker.service.end_time).minutes}</p>` +
            `<p>שניות: ${get_date_diff(worker.service.start_time, worker.service.end_time).seconds}</p>`+
        `</div>`, 
        confirmButtonText:'סיים משמרת',
        cancelButtonText: 'ביטול',
        showCancelButton: true,
        confirmButtonColor: '#28c76f',
        focusCancel:true,
    }).then(res => {
        if(res.isConfirmed){
            get_worker_from_shift(worker).shift.active = false
            get_worker_from_shift(worker).shift.total_hours = get_date_diff(worker.shift.start_time).hours
            get_worker_from_shift(worker).shift.total_minutes = get_date_diff(worker.shift.start_time).minutes
            get_worker_from_shift(worker).shift.end_time = new Date();
            
            if(get_worker_from_shift(worker).service.active){
                get_worker_from_shift(worker).service.active = false
                get_worker_from_shift(worker).service.total_hours = get_date_diff(worker.service.start_time).hours
                get_worker_from_shift(worker).service.total_minutes = get_date_diff(worker.service.start_time).minutes
                get_worker_from_shift(worker).service.end_time = new Date();
            }
            update_shift_in_db();
        }
    })
}
const get_worker_from_shift = (worker) => {
    if(!store.state.shift){
        return
    }
    let inx = store.state.shift.workers.findIndex(work => work.id == worker.id)
    if(inx != -1){
        if(store.state.shift.workers[inx].active){
            return store.state.shift.workers[inx]
        }else{
            return false
        }
    }else{
        return false
    }
}

export{clock_in_shift, get_worker_from_shift, clock_out_shift}