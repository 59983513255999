
const get_date_diff = (target, end_date = null) => {
    if(target){
        if(!end_date){
            let seconds= Math.floor((new Date() - target) / 1000);
            let minutes= Math.floor(seconds/60);;
            let hours= Math.floor(minutes/60);
            let days = Math.floor(hours/24);
        
            hours = hours-(days*24);
            minutes = minutes-(days*24*60)-(hours*60);
            seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
            return {hours:hours, minutes:minutes, seconds:seconds}
        }else{
            let seconds= Math.floor((end_date - target) / 1000);
            let minutes= Math.floor(seconds/60);;
            let hours= Math.floor(minutes/60);
            let days = Math.floor(hours/24);
        
            hours = hours-(days*24);
            minutes = minutes-(days*24*60)-(hours*60);
            seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
            return {hours:hours, minutes:minutes, seconds:seconds}
        }

    }else{
        return {hours:0, minutes:0, seconds:0}
    }

}

const get_salary_rate = () => {
    let rate = 43.68;
    return rate
}

export{get_date_diff, get_salary_rate}